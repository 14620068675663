<template lang="html">
  <header class="header">

    <div class="logoText-container">
      <img class="dyehard" src="img/dyehard.svg" alt="dyehard logo">
    </div>

    <div class="logo-container">
      <Logo class="logoSVG" />
    </div>

    <section class="address">
      1e Middellandstraat 125a<br>
      3021BD Rotterdam
    </section>

  </header>
</template>

<script>
import Logo from "@/assets/logo1.svg"

export default {
	components: {
		Logo
	},
}
</script>

<style lang="scss" scoped>
.header {
    text-align: center;
    position: relative;
    color: var(--main-color);

    @include media(">=phone") {
        padding: 100px 0;
    }

    @include media("<phone") {
        padding: 50px 0;
    }
}

h1 {
    font-size: 15vw;
    line-height: 0;
    letter-spacing: -0.02em;
}

.logo-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoSVG {
    display: block;
    height: 45%;
    width: 45%;
    position: relative;
    fill: var(--main-color);

    @include media(">=phone") {
        top: -4%;
    }

    @include media("<phone") {
        top: -6%;
    }
}

.logoText-container {
    background: var(--logo-color);
    mask: url("/img/dyehard.svg") center / contain no-repeat;
}

.dyehard {
    width: 65%;
    height: 65%;
    max-height: 400px;
    object-fit: contain;
    opacity: 0;
    pointer-events: none;
}

.address {
    margin-top: 20px;
}
</style>
