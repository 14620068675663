<template lang="html">
  <div class="answers">
    <Avatar type="response" />

    <ul class="items">

      <Message
        v-for="(answer, key) in data"
        :data="answer"
        :topic="topic"
        :key="`a_${key}`"
       />

    </ul>
  </div>
</template>

<script>
import Avatar from "@/components/parts/avatar"
import Message from "@/components/messages"

export default {
	props: {
		data: Array,
		topic: String
	},

	components: {
		Avatar,
		Message
	}
}
</script>

<style lang="scss" scoped>
.answers {
    display: flex;
    margin-top: 10px;
}

.items {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

::v-deep {
    .bubble {
        &.text {
            color: white;
        }
        &.bg-color {
            background-color: var(--main-color);
        }
    }
}
</style>
