<template lang="html">
  <div class="bubble text bg-color">
    <div class="wrapper">
      <div class="image" ref="image">
        <img :src="data.photo" />
      </div>

      <div class="meta" ref="meta">
        {{ data.name }}<br />
        <div class="info" ref="info"><br />{{ data.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { gsap } from "gsap/all";

  export default {
    props: {
      data: null,
    },

    data: () => ({
      active: false,
    }),

    mounted() {
      gsap.to(this.$refs.meta, { opacity: 1, delay: 0.6 });
    },

    // methods: {
    // 	toggleItem() {
    // 		this.active = !this.active
    // 	},
    // 	showItem() {
    // 		gsap.to(this.$refs.info, { height: 'auto', width: 'auto', opacity: 1 })
    // 		gsap.to(this.$refs.image, { width: 150, height: 200 })
    // 	},
    // 	hideItem() {
    // 		gsap.to(this.$refs.info, { height: 0, width: 0, opacity: 0 })
    // 		gsap.to(this.$refs.image, { width: 60, height: 60 })
    // 	}
    // },

    // watch: {
    // 	active() {
    // 		this.active ? this.showItem() : this.hideItem()
    // 	}
    // }
  };
</script>

<style lang="scss" scoped>
  .wrapper {
    @include media(">=phone") {
      display: flex;
      align-items: center;
    }
  }

  .image {
    border-radius: 15px;
    overflow: hidden;
    flex-shrink: 0;

    position: relative;

    @include media(">=phone") {
      width: 30%;
      padding-bottom: 42.0084269663%;
    }

    @include media("<=phone") {
      width: 100%;
      padding-bottom: 140.0280898876%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
  }

  .meta {
    opacity: 0;
    padding: 0 20px;

    @include media("<=phone") {
      padding: 10px 0 0;
    }
  }
</style>
