<template lang="html">
  <ul class="actions">
    <li
      v-for="(action, key) in actions"
      :key="key">

      <a class="action"
        :href="action.action"
        v-if="action.type=='link'"
        target="_blank">
        {{action.label}}
      </a>

    </li>
  </ul>
</template>

<script>
export default {

	props: {
		actions: Array
	}

}
</script>

<style lang="scss" scoped>
li,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

// .actions {
//     border-top: 2px solid white;
//     margin-top: 10px;
// }

.actions {
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    width: 100%;
}

li {
    width: 100%;
}

.action {
    box-sizing: border-box;
    width: 100%;
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 10px;
    background-color: rgba(255,255,255,0.25);
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.2s, transform 0.1s;

    &:hover {
        background-color: rgba(255,255,255,0.35);
    }

    &:active {
        transform: scale(0.95);
    }
}
</style>
