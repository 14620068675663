import Vue from 'vue'
import Vuex from 'vuex'
import window from './modules/window'
import chat from './modules/chat'
import colors from './modules/colors'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    window,
    colors,
    chat
  },
  strict: debug,
})