import Vue from "vue";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import App from "./App.vue";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueGtag, {
  config: { id: "G-1TERNEFHBT" },
});

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
