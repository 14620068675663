<template lang="html">
  <transition name="fade">
    <div class="buttons" v-if="!hideButtons">
      <div class="button-wrapper" :class="{'chatting': chatting}">
        <Button
          v-for="(button, key) in buttons"
          :key="key"
          :data="button"
          :class="`button_${key}`"
          />
      </div>
    </div>
  </transition>
</template>

<script>
import {
	mapState
} from "vuex"
import Button from "@/components/parts/button"

export default {

	components: {
		Button
	},

	computed: {
		...mapState( {
			topics: state => state.chat.topics,
			chatting: state => state.chat.chatting,
			finished: state => state.chat.finished,
			start: state => state.chat.start
		} ),

		buttons() {
			return Object.keys( this.topics )
				.reduce( ( r, e ) => {
					if( this.topics[ e ].label ) r[ e ] = this.topics[ e ]
					return r;
				}, {} )
		},

		hideButtons() {
			return !this.finished && !this.start;
		}
	},

}
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;

    @include media(">=phone") {
        bottom: 50px;
        transform: translateX(-50%);
        left: 50%;
    }

    @include media("<phone") {
        bottom: 20px;
    }

}

.button-wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: opacity 0.3s, transform 0.3s;

    &.chatting {
        pointer-events: none;
        opacity: 0;
        transform: scale(0.8);
    }

    @include media(">=phone") {
        max-width: 450px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
