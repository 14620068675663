const state = () => ({
  height: 0,
  width: 0,
  scrollY: 0,
  scrollX: 0,
  touch: false
});

const mutations = {
  setWindow(state, window) {
    state.height = (window.height != null) ? window.height : state.height;
    state.width = (window.width != null) ? window.width : state.width;
  },
  setScroll(state, scroll) {
    state.scrollY = (scroll.y != null) ? scroll.y : state.scrollY;
    state.scrollX = (scroll.x != null) ? scroll.x : state.scrollX;
  },
  setTouch(state, touch) {
    state.touch = touch
  }
};

const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}