<template lang="html">
  <div class="avatarWrapper" :class="type">
    <div class="avatar" ref="avatar">
      <Logo v-if="type == 'response'" />
      <div v-else class="user-image">
        <img :src="`img/avatar${randAvatar}.jpg`">
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/logo.svg"
import {
	gsap
} from "gsap/all"
import {
	mapState
} from "vuex"

export default {

	components: {
		Logo
	},

	props: {
		type: String
	},

	computed: {
		...mapState( {
			chat: state => state.chat.conversation
		} ),
		randAvatar() {
			return Math.floor( Math.random() * 2 ) + 1;
		}
	},

	methods: {
		animateHeight() {
			const a = this.$parent.$el.offsetHeight;
			const b = this.$el.offsetHeight;

			if( a == b ) return;

			gsap.to( this.$el, {
				height: a,
				duration: 0.3
			} )

		}
	},

	mounted() {
		gsap.from( this.$refs.avatar, {
			scale: 0,
			duration: 0.2
		} )
	},

	watch: {
		chat: {
			deep: true,
			handler() {
				this.animateHeight()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.avatarWrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include media(">=phone") {
        height: 80px;
    }

    @include media("<phone") {
        height: 40px;
    }
}

.avatar {
    border-radius: 100px;
    position: sticky;
    overflow: hidden;
    box-sizing: border-box;
    border-color: var(--main-color);
    border-style: solid;

    @include media(">=phone") {
        width: 80px;
        height: 80px;
        bottom: 200px;
        border-width: 3px;
    }

    @include media("<phone") {
        width: 40px;
        height: 40px;
        bottom: 140px;
        border-width: 2px;
    }

    .response & {
        @include media(">=phone") {
            margin-right: 20px;
        }
        @include media("<phone") {
            margin-right: 10px;
        }
    }

    .user & {
        background-color: var(--main-color);
        @include media(">=phone") {
            margin-left: 20px;
        }
        @include media("<phone") {
            margin-left: 10px;
        }
    }
}

svg {
    fill: var(--main-color);
    transform: scaleX(-1) scale(0.9) translateY(-3%);
}

.img,
.user-image {
    width: 100%;
    height: 100%;
    @media (prefers-color-scheme: light) {
        mix-blend-mode: lighten;
    }
    @media (prefers-color-scheme: dark) {
        mix-blend-mode: multiply;
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
