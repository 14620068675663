<template lang="html">
  <div class="bubble text bg-color">
    <div class="content" v-html="data.content"></div>
    <Actions v-if="data.actions" :actions="data.actions" />
  </div>
</template>

<script>
import Actions from "@/components/parts/actions"

export default {
	props: {
		data: null
	},

	components: {
		Actions
	}
}
</script>

<style lang="scss" scoped>
::v-deep {
    .flex{
      display: flex;
      justify-content: space-between;
    }

    .times{
      margin-left: 20px;
    }
}
</style>
