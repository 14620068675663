<template>
<div id="app">
	<Header />
	<Chat />
	<Buttons />
</div>
</template>

<script>
import Header from "@/components/header"
import Buttons from "@/components/buttons"
import Chat from "@/components/chat"
import { mapMutations, mapActions } from "vuex"

export default {
	name: 'App',

	components: {
		Header,
		Chat,
		Buttons
	},

	created() {
		this.setColor()
	},

	mounted() {
		//window.addEventListener('scroll', () => this.onScroll());
		window.addEventListener('resize', () => this.onResize());

		//this.onScroll();
		this.onResize();
		this.initApp();
	},

	methods: {
		...mapMutations({
			setWindow: 'window/setWindow',
			setScroll: 'window/setScroll',
			setColor: 'colors/setColor'
		}),

		...mapActions({
			initApp: 'chat/INIT'
		}),

		onResize() {
			this.setWindow({ width: window.innerWidth, height: window.innerHeight })
		},
		onScroll() {
			this.setScroll({ y: window.scrollY, x: window.scrollX })
		}
	},

	metaInfo: {
		title: 'DYEHARD KAPPER ROTTERDAM',
		htmlAttrs: {
			lang: 'nl',
			amp: true
		},
		meta: [
			{ charset: 'utf-8' },
			{ name: 'description', content: 'Of je nou precies weet wat je wilt of juist clueless bent, bij DYEHARD ga je de deur uit met een kapsel dat bij jou past!' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]
	}

}
</script>

<style lang="scss">
@import "@/assets/sass/normalize.scss";
@import "@/assets/sass/type.scss";
@import "@/assets/sass/themes.scss";

body {
    background-color: var(--bg-color);
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: 100vh;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
