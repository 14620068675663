<template lang="html">
  <li class="wrapper">
    <component :is="`Message${data.type}`" :data="data"/>
  </li>
</template>

<script>
import { gsap } from "gsap/all"
import Messagetext from '@/components/messages/text'
import Messagelink from '@/components/messages/link'
import Messagebook from '@/components/messages/book'
import Messagephoto from '@/components/messages/photo'
import Messagecrew from '@/components/messages/crew'

export default {

	props: {
		data: Object,
		topic: String
	},

	components: {
		Messagetext,
		Messagelink,
		Messagebook,
		Messagephoto,
		Messagecrew
	},

	// data: () => ( {
	// 	sound: {
	// 		response: new Audio('sounds/beep1.mp3'),
	// 		answer: new Audio('sounds/beep2.mp3')
	// 	}
	// } ),

	mounted() {
		this.showMessage()
	},

	computed: {
		answer() {
			return this.data.position == 'answer' ? true : false
		},
	},

	methods: {

		showMessage() {

			//this.playSound();

			gsap.from(this.$el, {
				scale: 0,
				transformOrigin: this.answer ? "left" : "right",
				force3D: true,
				delay: 0.1,
				ease: "back.out(1)",
				onComplete: () => gsap.set(this.$el, {
					clearProps: 'all'
				})
			})

		},

		// playSound() {
		// 	this.answer ? this.sound.answer.play() : this.sound.response.play()
		// }

	}

}
</script>

<style lang="scss" scoped>
.wrapper {
    display: inline-block;
    margin-bottom: 2px;
}

::v-deep .bubble {
    @include media(">=phone") {
        padding: 20px;
        border-radius: 25px;
    }

    @include media("<phone") {
        padding: 15px;
        border-radius: 20px;
    }
}
</style>
