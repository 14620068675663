<template lang="html">
    <div class="image" :style="`padding-bottom:${data.ratio}%`">
      <img :src="data.url">
    </div>
</template>

<script>

export default {
	props: {
		data: null
	},

	// mounted() {
	// 	gsap.to(this.$refs.meta, { opacity: 1, delay: 0.6 })
	// }
}
</script>

<style lang="scss" scoped>
.image {
    width: 100%;
    min-width: 250px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.meta {
    opacity: 0;
    font-size: 12px;
    padding: 10px 0;

    @media (prefers-color-scheme: light) {
        color: rgba(0,0,0,0.3);
    }

    @media (prefers-color-scheme: dark) {
        color: rgba(255,255,255,0.3);
    }
}
</style>
