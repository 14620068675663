<template lang="html">
  <div  class="question">
    <Avatar type="user" />

    <ul class="items">
      <Message :data="data"/>
    </ul>

  </div>
</template>

<script>
import Avatar from "@/components/parts/avatar"
import Message from "@/components/messages"

export default {

	props: {
		data: Object
	},

	components: {
		Avatar,
		Message
	}

}
</script>

<style lang="scss" scoped>
.items {
    margin: 0;
    padding: 0;
}
.question {
    display: flex;
    flex-direction: row-reverse;
}

::v-deep {
    .bubble {
        color: var(--main-color);
        background-color: var(--light-color);
    }
}
</style>
