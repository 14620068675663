import Vue from 'vue'
import Topics from '@/data/chat.json'

const state = () => ({
  topics: Topics,
  conversation: {},
  chatting: false,
  finished: false,
  start: false
});

const mutations = {

  toggleChat(state) {
    state.chatting = !state.chatting
    console.log('chatting', state.chatting);
  },

  startConversation(state, key) {
    Vue.set(state.conversation, key, {})
    Vue.set(state.conversation[key], 'answers', [])
  },

  addQuestion(state, key) {
    const question = {
      content: state.topics[key].question,
      type: 'text'
    }

    Vue.set(state.conversation[key], 'question', question)
  },

  addAnswer(state, { key, answer }) {
    state.conversation[key].answers.push(answer)
  },

  checkStartFinished(state) {
    console.log('check start');
    if (state.topics.start) {
      if (state.conversation.start) {
        state.start = true;
      }
    } else {
      state.start = true;
    }
  },

  checkFinished(state) {
    if (Object.keys(state.topics).length == Object.keys(state.conversation).length) {
      state.finished = true;
    }
  }

};

const actions = {

  INIT({ commit }) {
    commit('checkStartFinished')
  },

  async UPDATECONVERSATION({ commit, dispatch, state }, key) {
    commit('toggleChat')
    commit('startConversation', key)
    commit('checkFinished')

    if (state.topics[key].question) {
      commit('addQuestion', key)
    }

    await dispatch('ADDANSWERS', key)
    commit('checkStartFinished')
    commit('toggleChat')
  },

  ADDANSWERS({ state, commit }, key) {
    const items = state.topics[key].responses;
    const actions = items.map((item, i) => {
      i++;
      return new Promise(resolve => setTimeout(() => {

        item.position = 'answer';

        commit('addAnswer', {
          answer: item,
          key: key
        })

        resolve()
      }, i * 1000));
    })

    return Promise.all(actions);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
