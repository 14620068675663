import Colors from '@/data/colors.json'

const state = () => ({
  colors: Colors,
  activeColor: String
});

const mutations = {
  setColor(state) {
    state.activeColor = state.colors[Math.floor(Math.random() * state.colors.length)]
    document.body.setAttribute('theme', state.activeColor);
  }
};

const actions = {

};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}