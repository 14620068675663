<template lang="html">
  <main class="chat">

      <section v-for="(topic, key) in chat" :key="key" class="group">
        <Question v-if="topic.question" :data="topic.question"/>
        <Answers v-if="topic.answers.length > 0" :topic="key" :data="topic.answers"/>
      </section>

  </main>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Question from "@/components/parts/question"
import Answers from "@/components/parts/answers"

import { gsap, ScrollToPlugin } from "gsap/all"
gsap.registerPlugin(ScrollToPlugin);

export default {

	components: {
		Question,
		Answers
	},

	computed: {
		...mapState({
			chat: state => state.chat.conversation,
			topics: state => state.chat.topics
		})
	},

	mounted() {
		if(this.topics.start) {
			setTimeout(() => {
				this.startConversation('start')
			}, 500);

		}
	},

	methods: {

		...mapActions({
			startConversation: 'chat/UPDATECONVERSATION'
		}),

		scrollToMessage() {
			gsap.to(window, {
				duration: 1,
				scrollTo: {
					y: "max",
					autoKill: true
				},
				ease: "expo.out"
			});
		},

	},

	watch: {
		chat: {
			deep: true,
			handler() {
				setTimeout(() => {
					this.scrollToMessage();
				}, 100);

			}
		}
	}

}
</script>

<style lang="scss" scoped>
.chat {

    @include media(">=phone") {
        max-width: 600px;
        margin: 0 auto;
        padding: 0 20px 250px;
    }

    @include media("<phone") {
        padding: 0 10px 180px;
    }
}

.group {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        @include media(">=phone") {
            margin-bottom: 150px;
        }

        @include media("<phone") {
            margin-bottom: 100px;
        }
    }
}
</style>
