<template lang="html">
  <a :href="data.href" class="link">{{data.label}}</a>
</template>

<script>
export default {
	props: {
		data: null
	}
}
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    color: var(--main-color);
    border-color: var(--main-color);
    border-style: solid;
    background-color: var(--bg-color);
    box-sizing: border-box;
    border-radius: 100px;

    @include media(">=phone") {
        border-width: 3px;
        margin: 5px;
        padding: 10px 20px;
    }

    @include media("<phone") {
        border-width: 2px;
        margin: 3px;
        padding: 10px 15px;
    }
}
</style>
