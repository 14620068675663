<template lang="html">
  <div class="booking-wrapper">
    <div class="salonized-booking" data-company="d2Va5vw" data-color="dark" data-language="nl" data-height="500" data-inline="true"></div>
  </div>
</template>

<script>
export default {
	props: {
		data: null
	},
	mounted() {
		let script = document.createElement( 'script' )
		script.setAttribute( 'src', 'https://static-widget.salonized.com/loader.js' )
		document.head.appendChild( script )
	}
}
</script>

<style lang="postcss" scoped>
.booking-wrapper{
  height: 500px;
  border-radius: 25px;
  overflow: hidden;
  border: 5px solid var(--main-color);
  background-color: white;
}

::v-deep iframe{
  border-radius: 25px;
  overflow: hidden;
}

</style>
