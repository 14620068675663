<template lang="html">
  <div class="button" @click="onClickButton" :class="{'disabled' : disabled}">
    <div class="label-wrapper">
      {{data.label}}
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {

	props: {
		data: Object,
	},

	data: () => ({
		disabled: false
	}),

	methods: {

		...mapActions({
			addQuestion: 'chat/UPDATECONVERSATION',
		}),

		onClickButton() {
			this.disabled = true;
			this.addQuestion(this.$vnode.key)

      this.$gtag.event('question', {
        'event_label': this.$vnode.key,
        'value': this.$vnode.key
      })

		}

	}
}
</script>

<style lang="scss" scoped>
.button {
    background-color: var(--light-color);
    color: var(--main-color);
    border-radius: 20px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: opacity 0.3s, filter 0.3s;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.1s, transfrom 0.1s;
        border-radius: 20px;
    }

    &:hover {
        &:after {
            opacity: 0.05;
        }
    }

    &:active{
      transform: scale(0.95);
    }

    @include media(">=phone") {
        margin: 5px;
        padding: 10px 20px;
    }

    @include media("<phone") {
        margin: 3px;
        padding: 10px 15px;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        filter: blur(5px);
    }
}

.label-wrapper {
    white-space: nowrap;
    overflow: hidden;
}
</style>
